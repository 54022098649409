@font-face {
  font-family: Prompt;
  src: url('./fonts/Prompt/Prompt-Light.ttf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Prompt;
  src: url('./fonts/Prompt/Prompt-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Prompt;
  src: url('./fonts/Prompt/Prompt-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Prompt;
  src: url('./fonts/Prompt/Prompt-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}

html,
body {
  font-family: 'Prompt', sans-serif !important;
}
